window.angular.module('MyHippoProducer').service('authInterceptor', function ($q, $rootScope) {
    'ngInject';
    var service = this;
    service.responseError = function (response) {
        if (response.status === 401) {
            $rootScope.$broadcast('user.logout', response);
        }
        return $q.reject(response);
    };
}).config(function ($qProvider, $httpProvider, angularAuth0Provider, $mdDialogProvider) {
    'ngInject';
    $qProvider.errorOnUnhandledRejections(false);

    // config $httpProvider
    $httpProvider.defaults.headers.common['Cache-Control'] = 'no-cache';
    $httpProvider.defaults.cache = false;

    if (!$httpProvider.defaults.headers.get) {
        $httpProvider.defaults.headers.get = {};
    }
    $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';

    $httpProvider.interceptors.push('authInterceptor');

    // config angularAuth0Provider
    angularAuth0Provider.init({
        clientID: window.AUTH0_CLIENT_ID,
        domain: window.AUTH0_DOMAIN,
        redirectUri: window.location.origin,
        responseType: 'token id_token'
    });

    // Configure Modals
    $mdDialogProvider.addPreset('lenderModal', {
        options: function () {
            return {
                template: '<mortgage-modal mortgage="mortgage"></mortgage-modal>',
                parent: angular.element(document.body),
                locals: { mortgage: {} },
                bindToController: true,
                controller: function ($scope, mortgage) {
                    $scope.mortgage = mortgage;
                }
            };
        },
    });

    $mdDialogProvider.addPreset('confirmModal', {
        options: function () {
            return {
                parent: angular.element(document.body),
                template: `
                    <basic-modal title="title" primary-text="primary" secondary-text="secondary">
                        <p class="simple-text center" ng-repeat="text in texts">{{text}}</p>
                    </basic-modal>
                `,
                controller: function ($scope, locals) {
                    const { title, primary, secondary, texts } = locals;
                    $scope.title = title;
                    $scope.primary = primary;
                    $scope.secondary = secondary;
                    $scope.texts = texts;
                }
            };
        },
    });

});
